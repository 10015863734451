<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Drivers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/new-driver">
          <v-icon left dark>mdi-plus</v-icon>New Driver
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-text-field dense v-model="search" label="Search"></v-text-field>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="drivers"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
            >
              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/view-driver/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.vehicle_id="{ item }">
                <v-btn
                v-if="item?.vehicle"
                  color="primary"
                  text
                  :to="`/vehicle/${item.vehicle.id}`"
                  >{{ item.vehicle.RegistrationNO }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    drivers: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    headers: [
      { text: "Name", value: "RlpName" },
      { text: "Telephone", value: "Telephone" },
      { text: "Mobile", value: "Mobil" },
      { text: "Email", value: "Email" },
      { text: "Channel", value: "channel.name" },
      { text: "Tier", value: "tier.name" },
      { text: "Vehicle", value: "vehicle_id" },
      { text: "Action", value: "action" },
    ],
  }),
  methods: {
    clickedItem($event) {
      this.selectedEmployee = $event.item;
    },
    defaultModal() {
      if (this.selectedEmployee.id === undefined) {
        this.$refs.snackbar.show("Please select sales employee", "red");
      } else {
        this.dialog = true;
      }
    },
    save() {
      const data = {
        DfltSlp: SlpId,
      };
      const SlpId = this.selectedEmployee.id;
      const url = `/employee/set_default/${SlpId}`;
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getDrivers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res.ResponseData;
          console.log(res, "res");
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDrivers();
  },
};
</script>